import React, { useRef, useState, useEffect } from 'react';
import { track, Events } from 'utils/analytics';
import { useInView } from 'react-intersection-observer';

import { VideoWrapper, ReactPlayerStyled } from './VideoPlayer.style';

const VideoEmbed = ({
    url,
    title,
    onStart,
    onEnded,
    onProgress,
    progressEvents = [25, 50, 75],
    autoplayVideo,
}) => {
    const [ref, inView, entry] = useInView({
        threshold: [0.25, 0.75],
    });

    const [autoplayProps, setAutoplayProps] = useState({ playing: false });

    useEffect(() => {
        if (autoplayVideo && inView && entry && entry.intersectionRatio >= 0.75) {
            setAutoplayProps({ playing: true });
        } else if (autoplayVideo && (!inView || !entry || entry.intersectionRatio <= 0.25)) {
            setAutoplayProps({ playing: false });
        }
    }, [autoplayVideo, inView, entry]);

    const start =
        onStart ||
        (() => {
            track(Events.VIDEO_STARTED.create({ title }));
        });

    const end =
        onEnded ||
        (() => {
            track(Events.VIDEO_ENDED.create({ title }));
        });

    const mutechangefn = (isMuted) => {
        if (!isMuted) {
            track(Events.VIDEO_UNMUTED.create({ title }));
        }
    };
    let unbindmutefn = () => {};
    const reactPlayerRef = useRef();
    const onReady = (videoPlayerProperties) => {
        const videoHostName = videoPlayerProperties.player.player.constructor.displayName;

        if (videoHostName && videoHostName === 'Wistia') {
            const internalPlayer = reactPlayerRef.current.getInternalPlayer();

            internalPlayer.bind('mutechange', mutechangefn);
            unbindmutefn = () => {
                internalPlayer.unbind('mutechange', mutechangefn);
            };
        }
    };

    useEffect(() => unbindmutefn, []);

    const previousPercentageTrackedRef = useRef(-1);
    const trackPercentagePlayed = (percentagePlayed) => {
        if (previousPercentageTrackedRef.current !== percentagePlayed) {
            track(Events.VIDEO_PROGRESS.create({ title, progressPercentage: percentagePlayed }));
            previousPercentageTrackedRef.current = percentagePlayed;
        }
    };

    const progress = (progressData) => {
        const percentPlayed = progressData.played;

        const progressPoint = Math.max(
            ...progressEvents.filter((progressEvent) => progressEvent / 100 <= percentPlayed)
        ); // we get the last progressEvent before the current percentPlayed as that's the one we want to fire an event for.

        if (progressPoint >= 0) {
            trackPercentagePlayed(progressPoint);
        }

        if (typeof onProgress === 'function') {
            onProgress();
        }
    };
    return (
        <VideoWrapper ref={ref}>
            <ReactPlayerStyled
                ref={(playerRef) => {
                    reactPlayerRef.current = playerRef;
                }}
                data-testid="video-component"
                controls
                url={url}
                width="100%"
                height="100%"
                onStart={start}
                onEnded={end}
                onProgress={progress}
                onReady={onReady}
                volume={1}
                config={{
                    wistia: {
                        options: { plugin: { 'captions-v1': { onByDefault: autoplayVideo } } },
                    },
                }}
                muted={autoplayVideo}
                {...autoplayProps}
            />
        </VideoWrapper>
    );
};

export default VideoEmbed;
