import styled from 'styled-components';
import ReactPlayer from 'react-player/lazy';

const VideoWrapper = styled.div`
    position: relative;
    padding-top: 56.25%;

    /* makes sure that the close button is visible when a device is in landscape mode */
    /* and that the video is not being cut off */
    @media only screen and (orientation: landscape) {
        margin: 0 15%;
        padding-top: 48%;
    }
    /* 960 */
    @media only screen and (min-width: ${({ theme }) =>
            theme.screenSize.smallDesktop}px) and (orientation: landscape) {
        margin: 0;
        padding-top: 56.25%;
    }
`;

const ReactPlayerStyled = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`;

export { VideoWrapper, ReactPlayerStyled };
